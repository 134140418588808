@import "../settings/colors";
@import "../tools/mixins";

.ant-tabs {
	.ant-tabs-nav {
		.ant-tabs-tab {
			padding: 10px 8px;
		}
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			@include font(14px, 20px, 600, $text-2);
		}
	}
	.ant-tabs-ink-bar {
		background-color: $bg-btn-primary;
		height: 2px !important;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
.ant-tabs-top {
	&>.ant-tabs-nav {
		&:before {
			border-bottom: none;
		}
	}
}
