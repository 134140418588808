/* ==========================================================================
   Mixins
    - display,justify & align Properties here
   ========================================================================== */

@mixin displayalign($display, $justify, $align) {
	display: $display;
	justify-content: $justify;
	align-items: $align;
}

/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

/* ==========================================================================
     Mixins
      - Font Properties here
     ========================================================================== */

@mixin font($size, $lineHeight, $weight, $color) {
	font-size: $size !important;
	line-height: $lineHeight !important;
	font-weight: $weight !important;
	color: $color !important;
}


@mixin fontSize($size, $lineHeight, $weight) {
	font-size: $size;
	line-height: $lineHeight;
	font-weight: $weight;
}

/* ==========================================================================
     Mixins
      - Images Sizing Properties here
     ========================================================================== */
@mixin sizing($width, $height) {
	width: $width;
	height: $height;
}

@mixin size($size) {
	width: $size;
	height: $size;
}

/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
@mixin icon($size, $color) {
	font-size: $size;
	color: $color;
}

/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */

@mixin positionFull($position, $value) {
	position: $position;
	top: $value;
	bottom: $value;
	left: $value;
	right: $value;
}

@mixin positionTopLeft($position, $top, $left) {
	position: $position;
	top: $top;
	left: $left;
}

@mixin positionTopRight($position, $top, $right) {
	position: $position;
	top: $top;
	right: $right;
}

@mixin positionBottomLeft($position, $bottom, $left) {
	position: $position;
	bottom: $bottom;
	left: $left;
}

@mixin positionBottomRight($position, $bottom, $right) {
	position: $position;
	bottom: $bottom;
	right: $right;
}

/* ==========================================================================
   Mixins
    - Height Properties here
   ========================================================================== */

@mixin minMaxHeight($height) {
	min-height: $height;
	max-height: $height;
}

/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */

@mixin minMaxWidth($width) {
	min-width: $width;
	max-width: $width;
}

@mixin MinMaxWidth($minWidth, $maxWidth) {
	min-width: $minWidth;
	max-width: $maxWidth;
}

/* ==========================================================================
   Mixins
    - Responsive Properties here
   ========================================================================== */

@mixin desktop {
	@media only screen and (min-width: 1281px) {
		@content;
	}
}

@mixin laptop {
	@media only screen and (min-width: 1025px) and (max-width: 1280px) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (max-width: 1280px) {
		@content;
	}
}

@mixin tabletlandscape {
	@media only screen and (min-width: 769px) and (max-width: 1279px) {
		@content;
	}
}

@mixin ipad {
	@media only screen and (max-width: 768px) {
		@content;
	}
}

@mixin mobile {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

@mixin smallmobile {
	@media only screen and (max-width: 480px) {
		@content;
	}
}

@mixin xsmallmobile {
	@media only screen and (max-width: 385px) {
		@content;
	}
}
