/* =======================================================
   Maxis QA Colors Styles Here 
   ======================================================= */

// Common Colors
$white: #ffffff;
$black: #000000;
$maxisqa-primary: #23a68e;

// Text Colors
$text-1: #23a68e;
$text-2: #1a1a1a;
$text-3: #737373;
$text-4: rgba(255, 255, 255, 0.45);
$text-5: #197cf4;
$text-6: #B3B3B3;
$text-7: #CCE0E8;
$text-8: #737373;
$text-9: #5F7BB3;
$text-10: #D9D9D9;
$text-11: #808080;
$text-12: #85828B;
$text-13: #CCCCCC;
$text-14:#1F7BF4;

//Background Colors
$bg-grey-6: #B3B3B3;
$bg-blue-06: rgba(0, 148, 217, 0.06);
$bg-grey-04: #F5F5F5;
$bg-dark-primary: #17282f;
$bg-grey-secondary: #F9FAFB;
$bg-aqua:#EDF0F6;
$bg-aqua-haze: #F1F6F8;
$bg-grey-06: #F2F2F2;

// Button Colors
$bg-btn-primary: #23a68e;

//Border Colors
$border-1: rgba(255, 255, 255, 0.25);
$border-2: #E9E9E9;
$border-3: #1F7BF4;


// Box-shadow Colors

$box-shadow-1: rgba(23, 40, 47, 0.02);