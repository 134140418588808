* {
  box-sizing: border-box;
}


html,
body{
  height: -webkit-fill-available;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif !important;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
a {
  text-decoration: none;
  outline: none;
  cursor: pointer !important;
}
p {
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
  outline: none;
  color: $black;
  overflow-wrap: break-word;
  font-family: "Manrope" !important;
  line-height: 120% !important;
}

button {
  outline: none;
  cursor: pointer;
  outline-color: transparent;
  appearance: none;
}
button:focus,
button > .focus {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

::selection {
  color: var(--white);
  background: var(--primary-bg-2);
}

//App Classes

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  cursor: not-allowed;
}
.text-disabled {
  color: var(--text-hint);
  cursor: not-allowed;
}

//App Container

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}

.img-max {
  max-width: 100%;
}

.secondline-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

//Position Style Here

.bottom-0 {
  bottom: 0;
}

.top-0{
  top: 0;
}

.left-0{
  left: 0;
}

.right-0{
  right: 0;
}


/* border styles here */

.no-border {
	border: none !important;
}