@import "../settings/colors";
@import "../tools/mixins";

.ant-dropdown {
	.ant-dropdown-menu {
		@include border-radius(10px);
		padding: 8px;
		.ant-dropdown-menu-item {
			@include border-radius(4px);
			padding: 6px 10px;

			&:hover {
				background: $bg-aqua-haze;
			}
			.ant-dropdown-menu-title-content {
				@include font(14px, 20px, 500, $black);
			}
		}
	}
}

.ant-dropdown-open {
	background-color: $bg-grey-06;
	border-radius: 4px;
}
