@import "../settings/colors";
@import "../tools/mixins";

.ant-form-item {
	.ant-input {
		border-radius: 4px;
		padding: 8px 10px;
		&:hover,
		&:focus {
			border-color: $maxisqa-primary !important;
			outline: none;
			box-shadow: none;
		}

		&::placeholder {
			@include font(14px, 20px, 500, $text-6);
		}
	}

	.ant-input-affix-wrapper {
		border-radius: 4px;

		&>.ant-input {
			@include font(14px, 20px, 500, $text-2);
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

.ant-input-lg {
	@include font(14px, 20px, 500, $text-2);
}

.search-project {
	.ant-input-affix-wrapper {
		@include border-radius(4px);
		padding: 8px 10px;
		border: 1px solid $border-2;
		.ant-input-prefix {
			font-size: 20px;
			margin-right: 8px;
			color: $text-12;
		}
	}
}

.search-test-input {
	.ant-input-affix-wrapper {
		@include border-radius(4px);
		padding: 8px 12px;
		box-shadow: 0px 4px 24px $box-shadow-1;
		background-color: $white;
		border: none;
		.ant-input-prefix {
			color: $text-12;
			margin-right: 13px;
			font-size: 14px;
		}

		.ant-input {
			&::placeholder {
				@include font(14px, 20px, 500, $text-13);
			}
		}
	}
}
