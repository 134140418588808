@import "../settings/colors";
@import "../tools/mixins";

.ant-modal-content {
	@include border-radius(10px);
	@include MinMaxWidth(400px, 950px);
	.ant-modal-header {
		@include border-radius(10px);
		padding: 20px 24px 0;
		border-bottom: none;
		.ant-modal-title {
			@include font(20px, 28px, 700, $text-2);
		}
	}

	.ant-modal-close {
		color: $text-11;
		.ant-modal-close-x {
			font-size: 20px;
		}
	}
	.ant-modal-body {
		padding: 20px 24px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 0 20px 24px;
	}
}
