@import "../settings/colors";
@import "../tools/mixins";

.ant-avatar {
	@include font(18px, 18px, 700, $text-7);
	@include displayalign(flex, center, center);
	@include size(32px);
	border: none;
	background-color: $white;
}
