@import "../settings/colors";
@import "../tools/mixins";

.ant-btn {
	@include border-radius(4px);
	height: 40px;
	@include fontSize(14px, normal, 600);
	padding: 10px 24px;
	&.ant-btn-primary,
	&.btn-primary {
		color: $white;
		box-shadow: none;
		text-shadow: none;
		border-color: transparent;
		background: $bg-btn-primary !important;
	}
}
