@import "../settings/colors";
@import "../tools/mixins";

.ant-card {
	@include border-radius(12px);
	border: none;
	padding: 0;
	background-color: $white;
	box-shadow: 0px 4px 24px rgba(23, 40, 47, 0.02);
	.ant-card-body {
		padding: 20px;
	}
}
