@import "../settings/colors";
@import "../tools/mixins";

.project-cards {
	height: calc(100vh - 64px);
}

.test-suite-content {
	height: calc(100vh - 114px);
}

.no-found-data {
	height: calc(100vh - 290px);
}

.small-dot {
	@include size(4px);
	@include border-radius(50%);
	background: $bg-grey-6;
	display: inline-block;
}

.suite-title {
	h4 {
		@include font(14px, 19px, 500, $text-2);
	}
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.ant-btn[disabled], .ant-btn.btn-primary {
	background-color: #afd5ce;
}

.material-symbols-outlined {
	color: #00000066;
}