@import './includes';

//Font Size
$fontSizeConfig: 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 34, 36, 38, 40, 42,
  48;
@each $font in $fontSizeConfig {
  .f-#{nth($font, 1)} {
    font-size: #{nth($font, 1)}px !important;
  }
}

//Font-weight
$fontWeightConfig: 200, 300, 400, 500, 600, 700, 800, bold;
@each $weight in $fontWeightConfig {
  .f-w-#{$weight} {
    font-weight: $weight;
  }
}

//Line-height
$lineHeightConfig: 16, 18, 20, 22, 24, 26, 32;
@each $height in $lineHeightConfig {
  .l-h-#{nth($height, 1)} {
    line-height: #{nth($height, 1)}px;
  }
}

$lineHeightConfig1: normal, auto, 1;
@each $height in $lineHeightConfig1 {
  .l-h-#{nth($height, 1)} {
    line-height: #{nth($height, 1)};
  }
}

//Object Fit
$objectFit: contain, cover, fill, inherit, unset, none;
@each $obj in $objectFit {
  .object-#{nth($obj, 1)} {
    object-fit: nth($obj, 1);
  }
}


// color
$textColors: 'white' $white, 'black' $black, 'text-primary' $text-1, 'text-secondary' $text-2,
 'text-teritary' $text-3, 'grey-45' $text-4, 'dark-blue' $text-5,
  'grey-light' $text-6, 'grey-drak' $text-8, 'light-blue' $text-9 'grey-10' $text-10,
  'grey-11' $text-11, 'light-blue-14' $text-14;

@each $color in $textColors {
  .txt-#{nth($color, 1)} {
    color: nth($color, 2) !important;
  }
}

//Background color
$bgColors: 'dark-primary' $bg-dark-primary, 'sky-blue' $bg-blue-06, 'btn-primary' $bg-btn-primary,
 'grey' $bg-grey-04, 'aqua' $bg-aqua, 'white' $white, 'grey-secondary' $bg-grey-secondary;


@each $color in $bgColors {
  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2) !important;
  }
}

//Hover colors
@each $color in $textColors {
  .txt-hover-#{nth($color, 1)} {
    &:hover,
    &:focus {
      cursor: pointer;
      color: nth($color, 2) !important;
    }
  }
}

@each $color in $bgColors {
  .bg-hover-#{nth($color, 1)} {
    &:hover,
    &:focus {
      cursor: pointer;
      background: nth($color, 2) !important;
    }
  }
}

$hoverOpacity: 0 0, 75 0.75;
@each $opacity in $hoverOpacity {
  .o-#{nth($opacity, 1)} {
    &:hover,
    &:focus {
      cursor: pointer;
      opacity: nth($opacity, 2);
    }
  }
}

//Width
$sizeWidth: "100" 100%, "75" 75%, "50" 50%, "33" 33.3%, "25" 25%, auto auto, fit fit-content, 56 56px, 120 120px, 378 378px;
@each $size in $sizeWidth {
  .w-#{nth($size, 1)} {
    width: nth($size, 2) !important;
  }
}

$minMaxWidth: unset unset,50 50px, 220 220px, 296 296px, 300 300px, 360 360px, 400 400px, 500 500px, 560 560px,900 900px;
@each $size in $minMaxWidth {
  .mw-#{nth($size, 1)} {
    max-width: #{nth($size, 2)} !important;
  }
}

@each $size in $minMaxWidth {
  .minw-#{nth($size, 1)} {
    min-width: #{nth($size, 2)} !important;
  }
}

//Height
$sizeHeight: '100' 100%, '75' 75%, '50' 50%, '25' 25%;
@each $size in $sizeHeight {
  .h-#{nth($size, 1)} {
    height: nth($size, 2);
  }
}


$heights: 70, 120;

@each $height in $heights {
	.height-#{$height} {
		height: #{$height}+ px;
	}
}

$minHeights: 66, 100, 200, 400;

@each $height in $minHeights {
	.min-h-#{$height} {
		min-height: #{$height}+ px;
	}
}

$maxHeights: 372, 400, 750;

@each $height in $maxHeights {
	.max-h-#{$height} {
		max-height: #{$height}+ px;
	}
}

//Cursor
$cursorType: pointer, default, disabled, none;
@each $cursor in $cursorType {
  .cursor-#{nth($cursor, 1)} {
    cursor: nth($cursor, 1);
  }
}

//letter-space
$letterSpaceConfig: "normal" "normal", "1" "0.10px", "2" "0.20px", "3" "0.30px", "4" "0.40px", "5" "0.50px",
  "6" "0.60px", "7" "0.70px", "8" "0.80px", "9" "0.90px", "10" "1px", "13" "1.30px", "20" "2px";
@each $space in $letterSpaceConfig {
  .l-s-#{nth($space, 1)} {
    letter-spacing: #{nth($space, 2)};
  }
}

//Image Size
$imageSizes: 16, 30, 36, 40, 42, 48, 60, 80, 400;
@each $image in $imageSizes {
  .image-#{nth($image, 1)} {
    width: nth($image, 1) + px;
    height: nth($image, 1) + px;
  }
}



//Padding and Margin

$marginAlign: 'auto' auto, 'x-auto' auto, 'y-auto' auto;
@each $margin in $marginAlign {
  .m-#{nth($margin, 1)} {
    margin: nth($margin, 2);
  }
}

$spacerOrigin: 'm' margin, 'p' padding;
$spacerPosition: 't' top, 'b' bottom, 'r' right, 'l' left;
$spacerPositionXY: 'x' left right, 'y' top bottom;
$spacerSizing: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 26,31, 32, 40,45, 48, 50, 60, 70, 80;

@each $origin in $spacerOrigin {
  @each $position in $spacerPositionXY {
    @each $sizing in $spacerSizing {
      .#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
        #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) +
          px !important;
        #{nth($origin, 2)}-#{nth($position, 3)}: nth($sizing, 1) +
          px !important;
      }
    }
  }
}

@each $origin in $spacerOrigin {
  @each $sizing in $spacerSizing {
    .#{nth($origin, 1)}-#{nth($sizing, 1)} {
      #{nth($origin, 2)}: nth($sizing, 1) + px !important;
    }
  }
}

@each $origin in $spacerOrigin {
  @each $position in $spacerPosition {
    @each $sizing in $spacerSizing {
      .#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
        #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) +
          px !important;
      }
    }
  }
}

// Border Style
$borderStyle: border, border-top, border-bottom, border-left, border-right;
@each $borderValue in $borderStyle {
  .#{nth($borderValue, 1)}-grey {
    #{nth($borderValue, 1)}: 1px solid $border-1;
  }

  .#{nth($borderValue, 1)}-light-grey {
    #{nth($borderValue, 1)}: 1px solid $border-2;
  }

  .#{nth($borderValue, 1)}-blue {
    #{nth($borderValue, 1)}: 1px solid $border-3;
  }
  
}
@each $borderValue in $borderStyle {
  .#{nth($borderValue, 1)}-0 {
    #{nth($borderValue, 1)}: 0 !important;
  }
}

//Row and Column Border
@each $borderValue in $borderStyle {
  .row-#{nth($borderValue, 1)} > :not([hidden]) ~ :not([hidden]) {
    #{nth($borderValue, 1)}: 1px solid var(--border-4);
  }
}

//Text Style
$txtAlign: 'left' left, 'center' center, 'right' right;
@each $align in $txtAlign {
  .text-#{nth($align, 1)} {
    text-align: nth($align, 2);
  }
}

$txtTransform: uppercase, lowercase, capitalize;
@each $transform in $txtTransform {
  .text-#{$transform} {
    text-transform: $transform !important;
  }
}

$wordBreak: break-word, break-all, none, normal;
@each $break in $wordBreak {
  .word-#{nth($break, 1)} {
    word-break: nth($break, 1);
  }
}

$whiteSpace: wrap, nowrap, none, unset;
@each $space in $whiteSpace {
  .w-#{nth($space, 1)} {
    white-space: nth($space, 1);
  }
}

//Display Classes
$displayClasses: flex, block, inline-block, none, grid, inline-grid;
@each $display in $displayClasses {
  .d-#{$display} {
    display: $display !important;
  }
}

$flexWrap: wrap, no-wrap, none, warp-reserve, unset;
@each $wrap in $flexWrap {
  .flex-#{$wrap} {
    flex-wrap: $wrap;
  }
}

$flexDirection: row, column, row-reverse, column-reverse;
@each $direction in $flexDirection {
  .flex-#{nth($direction, 1)} {
    flex-direction: nth($direction, 1);
  }
}

$flexAlign: 'start' flex-start, 'center' center, 'end' flex-end,
  'between' space-between, 'stretch' stretch;
@each $align in $flexAlign {
  .align-#{nth($align, 1)} {
    align-items: nth($align, 2);
  }
}

$flexAlign: 'start' flex-start, 'center' center, 'end' flex-end,
  'between' space-between, 'stretch' stretch;
@each $align in $flexAlign {
  .align-content-#{nth($align, 1)} {
    align-items: nth($align, 2);
  }
}

$flexContent: 'start' flex-start, 'center' center, 'end' flex-end,
  'between' space-between, 'around' space-around, 'evenly' space-evenly;
@each $justify in $flexContent {
  .justify-#{nth($justify, 1)} {
    justify-content: nth($justify, 2) !important;
  }
}

$placeItems: 'start' start, 'center' center, 'end' end,
  'start-self' 'start-self', 'start-end' 'start-end';
@each $place in $placeItems {
  .place-#{nth($place, 1)} {
    place-items: nth($place, 2);
  }
}

//Column Gap

$sizesGap: 4, 8, 12, 16, 20, 24, 30, 32, 36;
@each $gap in $sizesGap {
  .g-#{nth($gap, 1)} {
    gap: nth($gap, 1) + px;
    -webkit-gap: nth($gap, 1) + px;
  }
  .c-g-#{nth($gap, 1)} {
    column-gap: nth($gap, 1) + px;
    -webkit-column-gap: nth($gap, 1) + px;
  }
  .r-g-#{nth($gap, 1)} {
    row-gap: nth($gap, 1) + px;
    -webkit-row-gap: nth($gap, 1) + px;
  }
}

//Position
$positionSide: relative, absolute, sticky, fixed, unset;
@each $position in $positionSide {
  .#{nth($position, 1)} {
    position: nth($position, 1);
  }
}

//icon
$iconConfig: 10, 12, 14, 16, 18, 20, 22;

@each $icon in $iconConfig {
  .i-#{nth($icon, 1)} {
    font-size: nth($icon, 1) + px !important;
  }
}

//Z-Index
$zIndex: -1,0, 1, 2, 100, 999, 9999;
@each $zindex in $zIndex {
  .index-#{nth($zindex, 1)} {
    z-index: nth($zindex, 1);
  }
}

//Cursor classes
$cursorMouse: pointer, not-allowed, none, default;
@each $cursor in $cursorMouse {
  .cursor-#{nth($cursor, 1)} {
    cursor: nth($cursor, 1) !important;
  }
}

//Border radius
$borderRadius: 2, 4, 6, 8, 10, 12, 20, 50;
@each $radius in $borderRadius {
  .radius-#{nth($radius, 1)} {
    border-radius: nth($radius, 1) + px !important;
  }
}

//Flex Classes
$flexFlow: 1, 2, 3, 4, 5;
@each $flow in $flexFlow {
  .flex-#{$flow} {
    flex: $flow;
  }
}

//Margin and Space

.space-ml-24 > :not([hidden]) ~ :not([hidden]) {
  margin-left: 24px;
}

.space-my-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px * var(--tw-space-y-reverse));
}


// Common Styles

.maxisqa-logo{
    img{
        width: 102px;
        height: 24px;
    }
}