@import "../settings/colors";
@import "../tools/mixins";

.ant-drawer {
  .ant-drawer-header {
    padding: 24px;
    border-bottom: none;
    .ant-drawer-header-title {
      flex-direction: row-reverse !important;
      .ant-drawer-title {
        @include font(20px, 28px, 700, $text-2);
      }
      .ant-drawer-close {
        font-size: 20px;
        color: $text-11;
        margin-right: 0;
      }
    }
  }
  .ant-drawer-footer {
    padding: 24px;
    @include displayalign("flex", "center", "end");
  }
}
