// @import "../settings/colors";
// @import "../tools/mixins";
.ant-avatar-group {
  .firsticon {
    position: relative;
    left: 20px;
  }
  .ant-avatar {
    z-index: 2;
  }
}
